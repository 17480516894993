// @ts-strict-ignore
import { useContext, useEffect } from "react";

import { Arrange, Toggle, Typography, useToast } from "@aviary";
import { useFlipperUpdateMutation } from "@shared/components/ExperimentTester/Flippers/useFlipperUpdate.mutation";
import { FlippersContext } from "@shared/context/FlippersContext";
import type { Flipper as FlipperType } from "@shared/types/flipper";

import * as styles from "./FlipperToast.styles";

const FlipperToast = () => {
  const { makeToast, removeToast } = useToast();

  const { setFlipper, selectedFlipper, setSelectedFlipper } = useContext(FlippersContext);
  const [updateFlipper, { loading }] = useFlipperUpdateMutation({
    onCompleted: () => handleComplete(),
  });
  const { name, enabled } = selectedFlipper ?? {};

  const renderContent = () => (
    <Arrange justify="start" isVertical>
      <Typography css={styles.font}>{name}</Typography>
      <Arrange>
        <Typography css={styles.font}>Status:</Typography>
        <Toggle
          isOn={enabled}
          onClick={onClick}
          onLabel="Enabled"
          offLabel="Disabled"
          isLoading={loading}
          css={styles.font}
        />
      </Arrange>
    </Arrange>
  );

  useEffect(() => {
    if (name) {
      removeToast("flipperToast");
      makeToast("normal", renderContent(), {
        toastId: "flipperToast",
        autoDismiss: false,
      });
    }
  }, [enabled, name]);

  if (!selectedFlipper) return null;

  const handleComplete = () => {
    const newValue = !enabled;
    setFlipper(name as FlipperType, newValue);
    setSelectedFlipper({ ...selectedFlipper, enabled: newValue });
  };

  const onClick = () => {
    updateFlipper({ variables: { input: { enable: !enabled, flipper: name } } });
  };

  return null;
};

export { FlipperToast };
