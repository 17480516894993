import { css } from "@emotion/react";

export const all = css`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  overflow-y: hidden;
  position: relative;
`;

export const content = css`
  margin-top: 2rem;
`;

export const pat = css`
  height: 6.25rem;
`;

export const tabHeaders = css`
  margin-left: auto;
  margin-right: auto;
`;

export const tester = css`
  // This is to display the experiment tester on top of the modal
  z-index: 10000;
`;

export const searchBar = css`
  min-width: 30rem;
`;

export const impersonateButton = css`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1;
`;
