import type { BackgroundQueryHookOptions, QueryReference, SkipToken } from "@apollo/client";
import { gql, useReadQuery, useBackgroundQuery } from "@apollo/client";

import type { Flipper } from "@shared/types/graphqlGenerated";

const Flippers_Shared_Query = gql`
  query Flippers_Shared_Query {
    viewer {
      flippers {
        id
        name
        result
      }
    }
  }
`;

type FlipperFragment = Pick<Flipper, "id" | "name" | "result">;
interface FlippersData {
  viewer: { flippers: FlipperFragment[] };
}

const useFlippersReadQuery = (ref: QueryReference<FlippersData>) => useReadQuery<FlippersData>(ref);
const useBackgroundFlippersQuery = (
  options?: BackgroundQueryHookOptions<FlippersData> | SkipToken
) => useBackgroundQuery<FlippersData>(Flippers_Shared_Query, options);

export type { FlippersData };

/* eslint-disable @fullscript/gql-no-manual-hook-declaration */
export { useFlippersReadQuery, useBackgroundFlippersQuery, Flippers_Shared_Query };
