import { css, type Theme } from "@emotion/react";
import { borders } from "aviary-tokens";

import { utilities } from "@styles";

export const wrapper = css`
  position: relative;
`;

export const inputStyles = (theme: Theme) => css`
  ${utilities.transition};
  background-color: ${theme.system.backgroundBase};
  border: 1px solid ${theme.system.borderBase};
  color: ${theme.system.textBase};
  border-radius: ${borders.radiusBase};
  font-size: 1rem;
  width: 100%;

  &::placeholder {
    ${utilities.transition};
    color: ${theme.text.subdued};
    /* Firefox */
    opacity: 1;
  }

  ::-webkit-search-cancel-button {
    display: none;
  }

  &:not([disabled]) {
    &:hover {
      border-color: ${theme.primary.borderHover};
    }

    &:active,
    &:focus {
      outline: none;
      border-color: ${theme.primary.borderActive};

      &::placeholder {
        color: ${theme.text.subdued};
        /* Firefox */
        opacity: 1;
      }
    }
  }
  &[disabled] {
    cursor: not-allowed;
    color: ${theme.disabled.text};
    background-color: ${theme.system.backgroundMutedHover};
    border-color: ${theme.disabled.border};
  }
`;

export const fullwidth = css`
  width: 100%;
`;

export const iconBase = (theme: Theme) => css`
  path {
    fill: ${theme.system.textBase};
  }
  position: absolute;
  padding: 0;
  padding-inline: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
`;

export const iconLocations = {
  start: css`
    left: 0.5rem;
  `,
  end: css`
    right: 0.5rem;
  `,
};

export const withIcon = {
  start: css`
    padding-left: 2.5rem;
  `,
  end: css`
    padding-right: 2.5rem;
  `,
};

export const inputSizes = {
  small: css`
    height: 2.25rem;
    padding: 1rem;
  `,
  normal: css`
    height: 2.5rem;
    padding: 1rem;
  `,
  large: css`
    height: 3.5rem;
    padding: 1rem;
  `,
};

export const closeBase = (theme: Theme) => css`
  position: absolute;
  right: 0.5rem;
  font-size: 1.25rem;
  height: 100%;

  path {
    fill: ${theme.system.textBase};
  }
`;
