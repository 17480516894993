import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const wordWrap = css`
  word-wrap: anywhere;
`;

export const flipper = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: ${dimensions.phoneLargeMax}) {
    gap: 0.25rem;
    align-items: start;
    flex-direction: column;
  }
`;

export const flipperName = css`
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const flipperActions = css`
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
`;
