// @ts-strict-ignore
import type { QueryReference } from "@apollo/client";
import { memo, type RefObject } from "react";
import { FixedSizeList } from "react-window";

import { Arrange } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";

import { Flipper } from "./Flipper";
import { useFlippersReadQuery, type FlippersData } from "./useFlippers.query";

interface Props {
  drawerRef: RefObject<HTMLDivElement>;
  flippersQueryRef: QueryReference<FlippersData>;
  onCloseDrawer: () => void;
  searchString: string;
}

const FlipperResults = memo(
  ({ drawerRef, flippersQueryRef, searchString, onCloseDrawer }: Props) => {
    const { data } = useFlippersReadQuery(flippersQueryRef);
    const { tablet } = useBreakpoints();

    const getFilteredResults = () => {
      if (searchString) {
        return data?.viewer?.flippers?.filter(flipper =>
          flipper.name.includes(searchString.toLowerCase())
        );
      }

      return [...data?.viewer?.flippers];
    };

    const filteredResults = getFilteredResults();

    return (
      <FixedSizeList
        innerElementType="div"
        height={(drawerRef.current?.offsetHeight ?? 600) - 365}
        width={drawerRef.current?.offsetWidth ?? 900}
        itemCount={filteredResults.length}
        itemSize={tablet.lessThan ? 85 : 50}
      >
        {({ index, style }) => {
          const { name, result } = filteredResults[index];

          return (
            <div style={style}>
              <Arrange isFullWidth={tablet.lessThan} isVertical alignment="center">
                <Flipper key={name} name={name} active={result} onCloseDrawer={onCloseDrawer} />
              </Arrange>
            </div>
          );
        }}
      </FixedSizeList>
    );
  }
);

export { FlipperResults };
