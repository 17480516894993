import { faPortalExit } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { IconButton, Link } from "@aviary";
import { sharedRoutes } from "@shared/data/sharedRoutes";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { l } from "@shared/locales/i18n";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

interface Props {
  isText?: boolean;
  size?: "xsmall" | "small" | "large";
  isCircular?: boolean;
}

const StopImpersonating = ({ isText, size, isCircular = true, ...rest }: Props) => {
  const { t } = useTranslation();
  const { isAdminImpersonating } = useSharedGlobalConfig();

  if (!isAdminImpersonating) return null;

  if (isText) {
    return (
      <Link
        isColor="danger"
        isUnderLined={false}
        href={sharedRoutes.stopImpersonating}
        target="_self"
        {...rest}
      >
        {t(l.sharedCommon.StopImpersonatingTitle)}
      </Link>
    );
  }

  return (
    <IconButton
      isColor="danger"
      isIcon
      aria-label={t(l.sharedCommon.StopImpersonatingTitle)}
      isCircular={isCircular}
      href={sharedRoutes.stopImpersonating}
      target="_self"
      size={size}
      {...rest}
    >
      <FontAwesomeIcon icon={faPortalExit} size="lg" />
    </IconButton>
  );
};

export { StopImpersonating };
