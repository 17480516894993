import { faExternalLinkSquare } from "@fortawesome/pro-regular-svg-icons";
import { useContext, useState } from "react";

import { IconButton, Toggle, Typography } from "@aviary";
import { FlippersContext } from "@shared/context/FlippersContext";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { FontAwesomeIcon } from "@shared/react-fontawesome";
import type { Flipper as FlipperType } from "@shared/types/flipper";

import { useFlipperUpdateMutation } from "./useFlipperUpdate.mutation";

import * as styles from "./Flipper.styles";

interface Props {
  name: string;
  active: boolean;
  onCloseDrawer: () => void;
}

const Flipper = ({ name, active, onCloseDrawer }: Props) => {
  const { tablet } = useBreakpoints();
  const [enabled, setEnabled] = useState(active);
  const { setFlipper, setSelectedFlipper } = useContext(FlippersContext);
  const [updateFlipper] = useFlipperUpdateMutation();

  const onClick = () => {
    setFlipper(name as FlipperType, !enabled);
    setEnabled(!enabled);
    updateFlipper({ variables: { input: { enable: !enabled, flipper: name } } });
  };

  const renderToast = () => {
    setSelectedFlipper({ name, enabled });
    onCloseDrawer();
  };

  return (
    <div css={styles.flipper}>
      <Typography
        type={tablet.lessThan ? "body" : "h3"}
        css={[tablet.lessThan && styles.wordWrap, styles.flipperName]}
        title={name}
        isMarginless
      >
        {name}
      </Typography>
      <div css={styles.flipperActions}>
        <Toggle isOn={enabled} onClick={onClick} onLabel="Enabled" offLabel="Disabled" />
        <IconButton
          intention="textSystem"
          onClick={renderToast}
          title="This will close the drawer and expose the flipper toggle inside a toast instead."
          aria-label="Expose flipper toggle inside toast"
          size="small"
        >
          <FontAwesomeIcon icon={faExternalLinkSquare} />
        </IconButton>
      </div>
    </div>
  );
};

export { Flipper };
