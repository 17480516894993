// @ts-strict-ignore
import { skipToken } from "@apollo/client";
import { useCallback, useMemo, useRef, useState } from "react";

import { Tabs, Arrange, Drawer, Typography, Spacer } from "@aviary";
import { Direction } from "@aviary/types";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useEventListener } from "@shared/hooks/useEventListener/useEventListener";
import { useKeyCombo } from "@shared/hooks/useKeyCombo/useKeyCombo";
import { useKeyPressed } from "@shared/hooks/useKeyPressed/useKeyPressed";
import { isProduction } from "@shared/utils/fullscriptEnv/fullscriptEnv";

import { StopImpersonating } from "../StopImpersonating/StopImpersonating";

import { ABTests } from "./ABTests/ABTests";
import { FlipperToast } from "./FlipperToast/FlipperToast";
import { Flippers } from "./Flippers/Flippers";
import { useBackgroundFlippersQuery } from "./Flippers/useFlippers.query";
import { Footer } from "./Footer/Footer";
import { Pat } from "./Pat";

import * as styles from "./ExperimentTester.styles";

const ExperimentTester = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentTabId, setCurrentTabId] = useState("A/B Tests");
  const { tablet } = useBreakpoints();
  const drawerRef = useRef(null);

  const onCloseDrawer = useCallback(() => {
    setIsOpen(false);
  }, []);

  const [queryRef] = useBackgroundFlippersQuery(isProduction() ? skipToken : {});
  const flippersQueryRef = useMemo(() => queryRef, []);

  useEventListener({
    eventName: "toggle-experiment-tester",
    listener: () => toggle(),
  });

  useKeyCombo({
    targetKeys: ["e"],
    withShift: false,
    onPressed: () => {
      setCurrentTabId("A/B Tests");
      toggle();
    },
  });

  useKeyCombo({
    targetKeys: ["e"],
    withShift: true,
    onPressed: () => {
      setCurrentTabId("Flippers");
      toggle();
    },
  });

  useKeyPressed("Escape", () => setIsOpen(false));

  const toggle = () => {
    setIsOpen(prev => !prev);
  };

  const renderFlippersTab = () => {
    if (isProduction()) return;

    return <Tabs.Header tabId="Flippers">Flippers</Tabs.Header>;
  };

  const renderFlippersContent = () => {
    if (isProduction()) return;

    return (
      <Tabs.Content tabId="Flippers">
        <Flippers
          drawerRef={drawerRef}
          flippersQueryRef={flippersQueryRef}
          onCloseDrawer={onCloseDrawer}
        />
      </Tabs.Content>
    );
  };

  const renderFooter = () => {
    if (!tablet.lessThan) return null;

    return <Footer />;
  };

  return (
    <>
      <FlipperToast />
      <Drawer
        isOpen={isOpen}
        onClickOutside={onCloseDrawer}
        hasBackdrop
        opensFrom={Direction.LEFT}
        isMaxIndex
        css={styles.tester}
        data-testid="experiment-tester"
        drawerChildRef={drawerRef}
      >
        <StopImpersonating css={styles.impersonateButton} />
        <div css={styles.all}>
          <Arrange isVertical alignment="center" css={styles.content}>
            <Pat />
            <Typography type="h1">Pat's Experiment Tester</Typography>
          </Arrange>
          <Spacer height="one" />
          <Tabs initialTabId={currentTabId}>
            <Tabs.Headers customHeadersStyle={styles.tabHeaders}>
              <Tabs.Header tabId="A/B Tests">A/B Tests</Tabs.Header>
              {renderFlippersTab()}
            </Tabs.Headers>
            <Tabs.Content tabId="A/B Tests">
              <ABTests />
            </Tabs.Content>
            {renderFlippersContent()}
          </Tabs>
          {renderFooter()}
        </div>
      </Drawer>
    </>
  );
};

export { ExperimentTester };
export default ExperimentTester;
