import type { QueryReference } from "@apollo/client";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Suspense, useState, type ChangeEvent, type RefObject } from "react";

import { Arrange, Skeleton, SkeletonList, TextField, Typography } from "@aviary";
import { Container } from "@aviary/layouts";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";

import * as styles from "../ExperimentTester.styles";

import { FlipperResults } from "./FlipperResults";
import type { FlippersData } from "./useFlippers.query";

interface Props {
  drawerRef: RefObject<HTMLDivElement>;
  flippersQueryRef: QueryReference<FlippersData>;
  onCloseDrawer: () => void;
}

const Flippers = ({ drawerRef, flippersQueryRef, onCloseDrawer }: Props) => {
  const { tablet } = useBreakpoints();
  const [searchString, setSearchString] = useState<string>("");
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>("");

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchString(value);
  };

  const handleDebounceChange = (updatedDebouncedSearch: string) => {
    setDebouncedSearchString(updatedDebouncedSearch);
  };

  const handleTrailingIconClick = () => {
    setSearchString("");
  };

  const renderFlipperResultsSkeleton = () => {
    return (
      <>
        <Typography>Loading loading loading, keep the flippers loading, show/hide!</Typography>
        <SkeletonList number={3}>
          <Skeleton isFullWidth type="button" margin="topAndBottom" />
        </SkeletonList>
      </>
    );
  };

  return (
    <Container>
      <Arrange isFullWidth={tablet.lessThan} justify="center" data-testid="flippers-content">
        <TextField
          isFullWidth={tablet.lessThan}
          placeholder="Search for flipper(s)"
          value={searchString}
          onChange={handleSearchChange}
          css={[!tablet.lessThan && styles.searchBar]}
          inputIcon={faSearch}
          hasClearButton={!!searchString}
          onClearField={handleTrailingIconClick}
          onDebouncedChange={handleDebounceChange}
          isDebounced
          autoFocus
        />
      </Arrange>
      <Suspense fallback={renderFlipperResultsSkeleton()}>
        <div css={styles.content}>
          <FlipperResults
            drawerRef={drawerRef}
            flippersQueryRef={flippersQueryRef}
            searchString={debouncedSearchString}
            onCloseDrawer={onCloseDrawer}
          />
        </div>
      </Suspense>
    </Container>
  );
};

export { Flippers };
